.bannerMain {
  /* position: relative; */
  /* height: 77vh; */
  padding-top: 20px;
  padding: 20px 20px;
}
.banner {
  background-image: url("../../../public/images/Group\ 65.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  height: 638px;
  width: 100%;
}
.bannerContent {
  position: absolute;
  margin-left: 100px;
  margin-top: 100px;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 45.6541px;
  line-height: 115.5%;
  color: #32689d;
}
.bannerdetails {
  margin-top: 20px;
}
.bannerdetails p {
  width: 804px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #6d6d6d;
}
.bannerButton {
  margin-top: 20px;
  margin-left: 10px;
}
.bannerButton button {
  border: none;
  width: 190px;
  height: 58px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 50%;
  background: linear-gradient(270deg, #76bbff 0%, #4087cc 51.69%, #32689d 100%);
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
}
.bannerButton button:hover {
  cursor: pointer;
  background: linear-gradient(270deg, #91c8ff 0%, #569adf 51.69%, #688baf 100%);
}
.bannerImgBg {
  overflow: hidden;
  position: absolute;
  margin-left: 780px;
  margin-top: 30px;
}

.bannerImgCircle {
  background: rgba(156, 182, 208, 0.3);
  width: 650px;
  height: 650px;
  border-radius: 50%;
  margin-bottom: -16%;
}
.bannerImgCircle img {
  width: 580px;
  margin-top: 170px;
  margin-left: 50px;
}
.endShadeMain {
  display: flex;
}
.endShadeLeft {
  width: 50%;
}

.endShadeLeftFirst {
  width: 406.09px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.endShadeLeftSecond {
  margin-top: 5px;
  width: 500.19px;
  height: 6px;

  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.endShadeLeftThird {
  margin-top: 5px;
  width: 650px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.endShadeRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.endShadeRightFirst {
  width: 406.09px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
}
.endShadeRightSecond {
  margin-top: 5px;
  width: 500.19px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
}
.endShadeRightThird {
  margin-top: 5px;
  width: 650px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
}

/* //////////////////////////////////////////////////////////////////////////////////// */

.endShadeLeftFirstMob {
  width: 406.09px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.endShadeLeftSecondMob {
  margin-top: 5px;
  width: 500.19px;
  height: 6px;

  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.endShadeLeftThirdMob {
  margin-top: 5px;
  width: 650px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.endShadeRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.endShadeRightFirstMob {
  width: 50px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
}
.endShadeRightSecondMob {
  margin-top: 5px;
  width: 500.19px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
}
.endShadeRightThirdMob {
  margin-top: 5px;
  width: 650px;
  height: 6px;
  background: linear-gradient(270deg, #32689d 0%, #4087cc 51.69%, #f4faff 100%);
  border-radius: 1px;
}
