.services {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.services .details {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.details h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 115.5%;
  letter-spacing: 0.04em;
  color: #4b4b4b;
}
.details span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 182%;
  letter-spacing: 0.03em;

  color: #6d6d6d;
}
