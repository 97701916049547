.footer {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 100px;
}
.logo img {
  width: 292px;
}
.navigation {
  display: flex;
  margin: 0 100px;
}
.location {
  display: flex;
  align-items: center;
}
.location div {
  margin-left: 10px;
  width: 260px;
  margin-top: 20px;
}
.location div span {
  width: 190px;
  font-size: 15.81px;
  color: #626262;
}
.location img {
  width: 17.08px;
}
.mail {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.mail span {
  margin-left: 10px;
  color: #626262;
}
.phone {
  display: flex;
  align-items: center;
}
.phone img {
  width: 16px;
}
.phone div {
  margin-left: 10px;
  margin-top: 10px;
  color: #626262;
}

.footer-company {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 5px 0;
}
.footer-company span h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16.2px;
  line-height: 18px;
  color: #626262;
}

.footer-company span {
  font-style: normal;
  font-weight: 400;
  font-size: 16.1975px;
  color: #626262;
}
.footer-help {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0;
}
.footer-help span h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16.2px;
  line-height: 18px;
  color: #626262;
}

.footer-help span {
  font-style: normal;
  font-weight: 400;
  font-size: 16.1975px;
  color: #626262;
}
.footer-contactRight {
  display: flex;
  flex-direction: column;
}
.footer-contactRight .head {
  display: flex;
  flex-direction: column;
}
.head span {
  color: #32689d;
  font-size: 12px;
}
.head p {
  color: #313131;
  font-size: 14px;
  font-weight: 400;
}
.head h1 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.footer-contactRight .corp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.corp span {
  color: #32689d;
  font-size: 12px;
}
.corp p {
  color: #313131;
  font-size: 14px;
  font-weight: 400;
}
.corp h1 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.footer-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-copyright span {
  font-weight: 400;
  font-size: 13.23px;
  text-align: center;
  color: #727272;
  padding: 20px 0;
}
