.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.navitems {
  display: flex;
  align-items: center;
}
.navitem {
  font-weight: 500;
  size: 20px;
  padding-right: 50px;
  text-decoration: none;
  color: inherit;
}
.navlogo img {
  width: 273px;
}
.navlogo {
  margin-left: 60px;
}
.navitems p :hover {
  cursor: pointer;
  color: white;
}
.navitems-div {
  display: flex;
  justify-content: space-between;
  margin-right: 70px;
}
.letsTalkDiv button {
  border: none;
  font-weight: 600;
  border-radius: 25px;
  width: 163px;
  height: 50px;
  color: #32689d;
  box-sizing: border-box;
  background: #f4faff;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
}
button :hover {
  background: #cfe6f9;
  cursor: pointer;
}
