.whychoose {
  margin: 20px 100px;
  box-sizing: border-box;
  border: 1px dashed #4a7aa9;
  border-radius: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* padding: 50px; */
}
.whychoose .content {
  margin-top: 20px;
  /* max-width: 1000px; */
}
.whychoose .head h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  color: #3e3e3e;
}
.whychoose .head span {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  color: #32689d;
}
.whychoose .content span {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #6d6d6d;
  max-width: 1100px;
  min-width: 700px;
  line-height: 182%;
  letter-spacing: 0.03em;
}
.head .divider {
  margin-top: 10px;
  content: "";
  height: 2px;
  background-image: linear-gradient(to right, #f4faff, #32689d, #f4faff);
}
