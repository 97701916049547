:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #32689d;
  --textColor: #4b4b4b;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding: 0 2rem;
  color: var(--textColor);
}

header a img {
  width: 273px;
}

nav a {
  margin: 0 0.8rem;
  color: var(--textColor);
  text-decoration: none;

  font-weight: 500;
  font-size: 15px;
  line-height: 115.5%;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: #444444;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.lets-talk {
  border: none;
  font-weight: 600;
  border-radius: 25px;
  width: 163px;
  height: 50px;
  color: #32689d;
  box-sizing: border-box;
  background: #f4faff;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
}
.lets-talk:hover {
  background: #e4f3ff;
  cursor: pointer;
}
header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header {
    height: 80px;
    /* position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1; */
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    position: relative;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #32689d;
    transition: 0.5s;
    transform: translateY(-100vh);
    z-index: 1;
  }

  header a img {
    width: 170px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #ffffff;
  }

  nav a {
    font-size: 1.5rem;
    color: #ffffff;
  }
  nav a:hover {
    font-size: 1.7rem;
    color: #ffffff;
  }
}
