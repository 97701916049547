.aboutus {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin: 0 70px;
}
.header .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.header .center h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  color: #4b4b4b;
}
.header .center h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #3e3e3e;
}
.content {
  display: flex;
  flex-direction: column;
  margin: 0 200px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #6d6d6d;
  gap: 20px;

  line-height: 160%;
  letter-spacing: 0.05em;
}

.about-footer {
  display: flex;
  justify-content: space-between;
  margin: 0 70px;
  margin-top: 20px;
}
.about-footer .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.about-footer .center h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #4b4b4b;
}
.about-footer .center h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #6d6d6d;
}
.about-footer .left img {
  width: 100px;
}
.about-footer .right img {
  width: 100px;
}
.header .left img {
  width: 200px;
}
.header .right img {
  width: 200px;
}
